<template>
  <div>
       <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="product.length>0" wrap>
      <v-flex v-for="(item, i) in product" :key="i" md3 pa-4>
        <v-card tile flat style="cursor: pointer" @click="info(item._id)"
          ><br />
          <v-img :src="baseURL + item.photos[0]" height="200px" contain></v-img>
          <v-layout wrap justify-center px-2>
            <v-flex
              pt-2
              xl12
              text-left
              style="line-height: 16px; cursor: pointer"
              @click="info(item._id)"
            >
              <span style="font-size: 13px; color: #000000">
                {{ item.productname }}
              </span>
              <br /><br />
              <!-- <span style="font-size: 13px; color: #000000">
                                {{ item.subcategory.name }}
                              </span>
                              <br /> -->
              <span style="font-size: 13px; color: #000000">
                Rs. {{ item.price }}
              </span>
              <span
                class="pl-12 text-decoration-line-through"
                style="font-size: 13px; color: #ff0000"
                v-if="item.offer > 0"
              >
                Rs. {{ item.orginalprice }}
              </span>
              <br />
              <span>
                <v-rating
                  v-model="item.rating"
                  color="#FFD341"
                  background-color="#e0e0e0"
                  empty-icon="$ratingFull"
                  half-increments
                  readonly
                  hover
                  small
                ></v-rating>
              </span>
              <br />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <div class="pt-2" v-if="pages > 1">
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </v-flex>
        </v-layout>
          </div>
    </v-layout>
    <v-card v-else height="30vh">
        <v-layout wrap   justify-center fill-height    >
      <v-flex md5 align-self-center >
        <h2>Oops!! No data found</h2>
      </v-flex>
    </v-layout>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["currentType","sellerid"],
  data() {
    return {
      productStatus: false,
      product: [],
      showsnackbar: false,
      appLoading:false,
      currentPage: 1,
      ServerError: false,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
    };
  },
  mounted() {
    this.productInfo();
  },
  watch:{
      currentType()
      {
          this.productInfo()
      },
      currentPage() {
      this.productInfo();
    },
  },
  methods: {
    productInfo() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/getbyseller",
        params: {
          sellerid: this.sellerid,
          currenttype: this.currentType,
          count: this.count,
          page: this.currentPage,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.productStatus = response.data.status;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
          if (response.data.status) {
            var temp = [];
            temp = response.data.data;
            if (temp.length > 8) {
              this.product = temp.slice(0, 8);
            } else {
              this.product = response.data.data;
            }
            this.photos = this.product.photos;
                       this.appLoading = false;

          } else {
             this.$emit("stepper", {
              msg: response.data.msg,
              showsnackbar: true,
            });
            this.appLoading = false;
          }
        })
        .catch((e) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(e);
        });
    },
    info(pid) {
      this.$router.push("/productDetails/" + pid);
    },
  },
};
</script>