<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-dialog
          v-model="reviewDialog"
          persistent
          max-width="600px"
          :key="reviewDialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!sellerData.rating"
              outlined
              color="green"
              v-bind="attrs"
              v-on="on"
            >
              <span class="item_title">Add Review</span>
            </v-btn>
            <v-btn v-else outlined color="green" v-bind="attrs" v-on="on">
              <span class="item_title">Edit Review</span>
            </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="item_title" v-if="!sellerData.rating">Add Review</span>
                <span class="item_title" v-else>Edit Review</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-rating
                        @input="addRating($event)"
                        v-model="rating"
                        background-color="black"
                        color="black"
                        required
                        half-increments
                        large
                      ></v-rating>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="reviewDialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="reviewAdd()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["sellerData", "sellerId"],
  data() {
    return {
      review: {
        rating: 0,
        name: null,
        comment: null,
      },
      appLoading: false,
      reviewDialog: false,
      showsnackbar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
    };
  },
  computed: {
    rating() {
      return this.sellerData.rating;
    },
  },
  watch: {
    rating() {
      this.rating = this.review.rating;
    },
  },

  methods: {
    addRating(value) {
      this.review.rating = value;
    },
    reviewAdd() {
      this.appLoading = true;
      var data = {};
      //   data["name"] = this.name;
      //   data["comment"] = this.comment;
      data["rating"] = this.review.rating;
      data["id"] = this.sellerId;
      axios({
        url: "/user/AdminEdit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;

          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.$emit("stepper", {
              type: "rating",
              getData: true,
            });
            Object.keys(this.review).forEach(
              (key) => (this.review[key] = null)
            );
            // this.name = null;
            // this.comment=null;
            this.reviewDialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>