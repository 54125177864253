<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            Seller Details
            <v-spacer></v-spacer>
            <v-col cols="6" md="2" v-if="user.status=='Active' || user.status=='InActive'">
                <v-switch
                  v-model="user.status"
                  true-value="Active"
                  false-value="InActive"
                  color="#5DC57C"
                  hide-details
                  :ripple="false"
                  class="statusKey"
                  @click="changeStatus(user)"
                >
                  <template slot="label">
                    <span
                      style="
                        color: #000000;
                        font-size: 16px;
                      "
                    >
                      {{ user.status }}
                    </span>
                  </template>
                </v-switch>
            </v-col>
            <v-col cols="6" md="2" class="py-2 px-11">
              <v-btn outlined color="red" @click="edit(user._id)">
                <span class="item_title">Edit Profile</span>
              </v-btn>
            </v-col>
            <v-col cols="6" md="2" class="py-2 px-11">
              <SellerRating
                v-bind:sellerData="seller"
                v-bind:sellerId="sellerid"
                @stepper="winStepper"
              />
            </v-col> </v-card-title
          ><br />
          <!-- <b-container v-if="report"> -->
          <div v-if="user">
            <v-row>
              <v-img
                cover
                class="pa-15"
                :src="baseURL + seller.coverimage"
                height="300px"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-col cols="9" class="pa-15">
                  <div v-viewer>
                    <v-avatar
                      v-if="user.image"
                      size="150"
                      style="cursor: pointer"
                    >
                      <img
                        style="object-fit: cover"
                        :src="baseURL + user.image"
                      />
                    </v-avatar>
                  </div>
                </v-col>
              </v-img>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-tabs v-model="tab" background-color="white" color="red" grow>
                  <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
                  <!-- <v-tabs-items v-model="tab"> -->
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text
                        ><v-row>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">First Name</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.firstname
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Last Name</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.lastname
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Username</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.username
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Phone</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.phone
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col v-if="user.whatsapp" cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Whatsapp</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.whatsapp
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Pincode</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.pincode
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">Email</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.email
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">Account Type</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.accounttype
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">City</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.city
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">Country</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.country
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">District</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.district
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">Locality</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.locality
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">Street Address</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.streetAddress
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="4" md="5">
                                <span class="item_title">Organization</span>
                              </v-col>
                              <v-col cols="8" md="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.organization
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title"
                                  >Commision Percentage</span
                                >
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.commisionPercentage
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">User Status</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.status
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Role</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  user.type
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title"
                                  >Account Created On</span
                                >
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2"
                                  >{{ formatDate(user.account_create_at) }}
                                </span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col v-if="seller.facebook" cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Facebook URL</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.facebook
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col v-if="seller.instagram" cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Instagram URL</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.instagram
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="6" class="text-center">
                            <v-card outlined class="pa-4">
                              <span class="item_title">Rating</span>
                              <v-rating
                                :value="parseFloat(seller.rating)"
                                background-color="black"
                                color="black"
                                readonly
                                required
                                half-increments
                                large
                              ></v-rating>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <div class="mt-5">
                            <template>
                              <v-dialog v-model="dialog" max-width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    outlined
                                    color="red"
                                    >Remove Seller</v-btn
                                  >
                                </template>
                                <v-card>
                                  <v-card-title
                                    >Are you sure you want to remove this
                                    Seller?</v-card-title
                                  >
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="dialog = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="deleteuser()"
                                      >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                          </div>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Account Holder</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.accountholder
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Account Number</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.accountnumber
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Bank Name</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.bankname
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">Branch</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.branch
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">GST Id</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.gstid
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row>
                              <v-col cols="5">
                                <span class="item_title">IFSC Code</span>
                              </v-col>
                              <v-col cols="7">
                                <span class="item_value">-</span>
                                <span class="item_value ma-2">{{
                                  seller.ifsccode
                                }}</span>
                              </v-col>
                            </v-row>
                            <hr />
                          </v-col> </v-row
                      ></v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-layout>
                      <v-flex xs2 sm2 md3 lg3 xl2 hidden-sm-and-down>
                        <v-layout wrap justify-start>
                          <v-flex xs11>
                            <v-card class="mx-auto" max-width="500" tile flat>
                              <v-list>
                                <template v-for="(item, i) in navItems">
                                  <v-list-item :key="item.name">
                                    <v-list-item-title>
                                      <v-layout
                                        @click="currentType = item.type"
                                        wrap
                                        justify-center
                                      >
                                        <v-flex xs12 text-uppercase>
                                          <span
                                            :style="
                                              currentType == item.type
                                                ? {
                                                    'font-family':
                                                      'poppinssemibold',
                                                    color: '#FF2323',
                                                  }
                                                : {
                                                    'font-family':
                                                      'poppinslight',
                                                    color: '#545353',
                                                  }
                                            "
                                            style="
                                              font-size: 18px;
                                              letter-spacing: 1px;
                                            "
                                            >{{ item.name }}</span
                                          >
                                        </v-flex>
                                      </v-layout>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-divider :key="i"></v-divider>
                                </template>
                              </v-list>
                            </v-card>
                          </v-flex>
                          <v-flex xs1>
                            <v-divider vertical></v-divider>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm12 md9 lg9 xl10>
                        <v-layout wrap justify-start>
                          <!-- <v-divider vertical></v-divider> -->
                          <v-flex xs12 sm12 md12>
                            <ProductDetails
                              :currentType="currentType"
                              :sellerid="sellerid"
                              @stepper="winStepper"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import SellerRating from "./sellerRating";
import ProductDetails from "./productDetails";
export default {
  components: { SellerRating, ProductDetails },
  props: ["sellerid"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      user: [],
      photos: [],
      seller: [],
      subcat: [],
      productStatus: false,
      product: [],
      productArray: [],
      categoryArray: [],
      msg: null,
      widthOfCard: "250px",
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      tab: null,
      text: "",
      reviewDialog: false,
      navItems: [
        { name: "Products", type: null },
        { name: "Offers", type: "Offer" },
        { name: "Deals", type: "Deals" },
      ],
      items: [" Profile Details", "  Bank Details", " Product Details"],
      currentType: null,
    };
  },
  mounted() {
    this.getData();
    this.productInfo();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "rating") {
        if (window_data.getData) this.getData();
      }
      if (window_data.showsnackbar) {
        this.showsnackbar = true;
        this.msg = window_data.msg;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/seller/info",
        params: {
          id: this.sellerid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            this.seller = response.data.data.seller;
            // this.userStatus = this.user.status;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeStatus(item) {
      axios({
        method: "POST",
        url: "/user/changestatus",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Status Changed to " + item.status;
            this.showSnackBar = true;
            this.getData()
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteuser() {
      axios({
        url: "/seller/delete",
        method: "GET",
        params: {
          id: this.sellerid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    productinfo(pid) {
      this.$router.push("/sellerProducts/products/" + pid);
    },
    edit(sid) {
      this.$router.push("/sellers/editSeller/" + sid);
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>