import { render, staticRenderFns } from "./sellerDetails.vue?vue&type=template&id=5c991168&scoped=true"
import script from "./sellerDetails.vue?vue&type=script&lang=js"
export * from "./sellerDetails.vue?vue&type=script&lang=js"
import style0 from "./sellerDetails.vue?vue&type=style&index=0&id=5c991168&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c991168",
  null
  
)

export default component.exports